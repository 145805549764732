import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LoginIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 2a.5.5 0 01.5-.5h2.667A1.833 1.833 0 0114.5 3.333v9.334a1.833 1.833 0 01-1.833 1.833H10a.5.5 0 010-1h2.667a.833.833 0 00.833-.833V3.333a.833.833 0 00-.833-.833H10a.5.5 0 01-.5-.5zM6.313 4.313a.5.5 0 01.707 0l3.334 3.333a.5.5 0 010 .708L7.02 11.687a.5.5 0 01-.707-.707l2.48-2.48H2a.5.5 0 010-1h6.793l-2.48-2.48a.5.5 0 010-.707z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
