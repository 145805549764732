import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const AnxiousSweatEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M6.357 20.256a10 10 0 10-4.166-10.201M8.797 17.592c-.01.166.126.3.291.3h5.829a.288.288 0 00.292-.3c-.108-1.88-1.25-2.735-3.206-2.735"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M14.917 17.893a.288.288 0 00.292-.3c-.108-1.88-1.25-2.736-3.206-2.736s-3.098.856-3.206 2.735c-.01.166.126.3.291.3h5.829z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M8.967 13.04a.682.682 0 100-1.365.682.682 0 000 1.364zm6.071 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
      <path
        d="M16.115 8.454c1.083 1.16 1.578 1.344 3.32 1.81M8.246 8.453c-1.082 1.16-1.577 1.344-3.32 1.81"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M3.238 12.56l.509.428-.509-.429zm.295 0l-.509.428.509-.429zm-.804-.429c-.251.299-.777.943-1.243 1.646-.233.352-.46.731-.631 1.1-.164.352-.31.764-.31 1.158h1.33c0-.1.046-.297.186-.598.133-.285.32-.603.534-.925.425-.642.914-1.242 1.152-1.524l-1.018-.857zM.545 16.035c0 .882.356 1.586.916 2.059.545.46 1.248.674 1.924.674.677 0 1.38-.213 1.925-.674.56-.473.916-1.177.916-2.059h-1.33c0 .497-.188.827-.444 1.043-.27.229-.655.36-1.066.36-.411 0-.796-.132-1.067-.36-.256-.216-.444-.546-.444-1.043H.545zm5.68 0c0-.394-.145-.806-.31-1.158a8.671 8.671 0 00-.63-1.1 19.038 19.038 0 00-1.243-1.646l-1.018.857c.238.282.727.882 1.152 1.524.213.322.401.64.534.925.14.302.186.499.186.598h1.33zm-2.478-3.047a.473.473 0 01-.723 0l1.018-.857a.857.857 0 00-1.313 0l1.018.857z"
        fill={color || 'currentColor'}
      />
    </BaseEmojiIcon>
  )
}
