import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const SearchUserIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.833 7.333a5.833 5.833 0 112.078 4.464l-2.557 2.557a.5.5 0 11-.708-.707l2.558-2.557a5.81 5.81 0 01-1.37-3.757zm2.47 3.471a.513.513 0 00-.107-.107 4.833 4.833 0 11.107.107z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.667 5.166a.833.833 0 100 1.667.833.833 0 000-1.667zm-1.834.833A1.833 1.833 0 1110.5 6a1.833 1.833 0 01-3.667 0zm-1 5c0-1.338 1.005-2.5 2.333-2.5h1c1.33 0 2.334 1.162 2.334 2.5v1.13a.5.5 0 01-.281.45 5.812 5.812 0 01-2.552.588c-.915 0-1.781-.212-2.553-.588a.5.5 0 01-.28-.45V11zm2.333-1.5c-.696 0-1.333.63-1.333 1.5v.807c.565.232 1.184.36 1.834.36.65 0 1.268-.128 1.833-.36V11c0-.87-.637-1.5-1.333-1.5h-1z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
