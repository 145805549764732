import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const TrashIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.833 4a.5.5 0 01.5-.5h9.334a.5.5 0 01.5.5v9.333a1.833 1.833 0 01-1.834 1.834H4.667a1.833 1.833 0 01-1.834-1.834V4zm1 .5v8.833a.833.833 0 00.834.834h6.666a.833.833 0 00.834-.834V4.5H3.833z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.667 1.834a.833.833 0 00-.834.833v.834h4.334v-.834a.833.833 0 00-.834-.833H6.667zm4.5 1.667v-.834A1.833 1.833 0 009.333.834H6.667a1.833 1.833 0 00-1.834 1.833v.834H2a.5.5 0 000 1h12a.5.5 0 000-1h-2.833zm-4.5 3.333a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zm2.666 0a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
