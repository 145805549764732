import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LogOutIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 2.5a.833.833 0 00-.833.833v9.334a.833.833 0 00.833.833H6a.5.5 0 010 1H3.333A1.833 1.833 0 011.5 12.667V3.333A1.833 1.833 0 013.333 1.5H6a.5.5 0 010 1H3.333zm6.98 1.813a.5.5 0 01.707 0l3.334 3.333a.5.5 0 010 .708l-3.334 3.333a.5.5 0 01-.707-.707l2.48-2.48H6a.5.5 0 010-1h6.793l-2.48-2.48a.5.5 0 010-.707z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
