import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CalendarXIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 2.5a.833.833 0 00-.833.833v9.334c0 .46.373.833.833.833h9.334c.46 0 .833-.373.833-.833V3.333a.833.833 0 00-.833-.833H3.333zM1.5 3.333c0-1.012.82-1.833 1.833-1.833h9.334c1.012 0 1.833.82 1.833 1.833v9.334c0 1.012-.82 1.833-1.833 1.833H3.333A1.833 1.833 0 011.5 12.667V3.333z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.333.167a.5.5 0 01.5.5v2.666a.5.5 0 11-1 0V.667a.5.5 0 01.5-.5zm5.334 0a.5.5 0 01.5.5v2.666a.5.5 0 01-1 0V.667a.5.5 0 01.5-.5zM1.5 6a.5.5 0 01.5-.5h12a.5.5 0 010 1H2a.5.5 0 01-.5-.5zm4.646 2.146a.5.5 0 01.708 0L8 9.293l1.146-1.147a.5.5 0 11.708.707L8.707 10l1.147 1.146a.5.5 0 01-.708.707L8 10.707l-1.146 1.146a.5.5 0 11-.708-.707L7.293 10 6.146 8.853a.5.5 0 010-.707z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
