import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const EyeOffIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.313.313a.5.5 0 01.707 0L15.687 14.98a.5.5 0 01-.707.707l-3.078-3.078c-1.08.707-2.39 1.224-3.902 1.224-2.533 0-4.49-1.446-5.781-2.824A13.62 13.62 0 01.355 8.474a8.088 8.088 0 01-.125-.23L.223 8.23.22 8.226.22 8.224.667 8 .22 7.776l.001-.003.004-.007.012-.023c.01-.02.025-.05.045-.086a13.454 13.454 0 01.848-1.338c.507-.705 1.23-1.57 2.154-2.328L.314 1.02a.5.5 0 010-.708zm3.681 4.389c-.871.699-1.562 1.52-2.053 2.2A12.453 12.453 0 001.235 8a12.616 12.616 0 001.713 2.325C4.156 11.613 5.866 12.833 8 12.833c1.197 0 2.26-.383 3.177-.949L9.38 10.086a2.5 2.5 0 01-3.464-3.464l-1.92-1.92zm2.652 2.651a1.5 1.5 0 002 2l-2-2zM.666 8L.22 7.776a.502.502 0 000 .448L.667 8zM8 3.167a5.58 5.58 0 00-1.297.152.5.5 0 11-.232-.972A6.58 6.58 0 018 2.167c2.533 0 4.49 1.446 5.781 2.824a13.616 13.616 0 011.864 2.535 7.984 7.984 0 01.125.23l.007.014.002.004.001.002-.447.224.447.224v.001l-.002.004-.005.01-.02.039-.075.138c-.065.118-.162.286-.288.49-.253.406-.627.96-1.118 1.548a.5.5 0 01-.768-.64A12.842 12.842 0 0014.764 8a12.616 12.616 0 00-1.713-2.325C11.845 4.387 10.135 3.167 8 3.167zM15.333 8l.447.224a.502.502 0 000-.448L15.333 8z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
