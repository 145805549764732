import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const StopIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 15 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M12.3611 1.75098H2.63889C1.87183 1.75098 1.25 2.3728 1.25 3.13987V12.8621C1.25 13.6291 1.87183 14.251 2.63889 14.251H12.3611C13.1282 14.251 13.75 13.6291 13.75 12.8621V3.13987C13.75 2.3728 13.1282 1.75098 12.3611 1.75098Z"
        stroke={color || 'currentColor'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
