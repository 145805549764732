import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const DollarSignCircleIcon = ({
  size = 'medium',
  color,
  strokeWidth = 2,
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      size={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 5.5V18.5M14.9167 7.86364H10.5417C10.0002 7.86364 9.48088 8.08153 9.09799 8.46939C8.7151 8.85725 8.5 9.3833 8.5 9.93182C8.5 10.4803 8.7151 11.0064 9.09799 11.3942C9.48088 11.7821 10.0002 12 10.5417 12H13.4583C13.9998 12 14.5191 12.2179 14.902 12.6058C15.2849 12.9936 15.5 13.5197 15.5 14.0682C15.5 14.6167 15.2849 15.1427 14.902 15.5306C14.5191 15.9185 13.9998 16.1364 13.4583 16.1364H8.5M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
