import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PlateUtensilsIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M13.879 10.777h-1.012c-.04 0-.078-.002-.116-.004A5.497 5.497 0 018 13.5a5.485 5.485 0 01-4.057-1.786v1.365a6.5 6.5 0 009.935-2.302zM5.424 2.03l.086 1.065A5.477 5.477 0 018 2.5c1.317 0 2.525.463 3.472 1.234.1-.33.237-.644.417-.943A6.471 6.471 0 008 1.5c-.915 0-1.786.19-2.576.53z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.833 9.833v4.5a.5.5 0 101 0V2.438a.792.792 0 00-1.104-.73c-.847.366-1.515.794-1.955 1.45-.441.657-.607 1.47-.607 2.509v3.366a.8.8 0 00.8.8h1.866zm0-7.074c-.601.291-.982.59-1.229.957-.284.422-.437 1.008-.437 1.95v3.167h1.666V2.76z"
        fill={color || 'currentColor'}
      />
      <path
        d="M1.04 1.835a.5.5 0 01.458.539l-.245 3.033a.5.5 0 00.498.54h.25V2.334a.5.5 0 011 0v3.615h.248a.5.5 0 00.498-.54l-.245-3.034a.5.5 0 11.996-.081l.246 3.033A1.5 1.5 0 013.25 6.948H3v7.385a.5.5 0 11-1 0V6.948h-.249A1.5 1.5 0 01.256 5.326l.246-3.033a.5.5 0 01.538-.458z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM5.5 8a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
