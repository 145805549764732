import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const HeartDuotoneIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  opacity,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.40421 12.1646C3.53215 10.7222 1.33336 9.02812 1.33336 6.0914C1.33336 2.84944 5.00013 0.550309 8.00002 3.66709V13.6667C7.33336 13.6667 6.66669 13.1529 5.97451 12.6073C5.78983 12.4617 5.59878 12.3145 5.40421 12.1646Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M10.0255 12.6073C11.9871 11.0609 14.6667 9.33335 14.6667 6.0914C14.6667 2.84944 10.9999 0.550309 8.00002 3.66709V13.6667C8.66668 13.6667 9.33335 13.1529 10.0255 12.6073Z"
        fill="black"
        fillOpacity={opacity || '0.4'}
      />
    </BaseSvgIcon>
  )
}
