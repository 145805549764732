import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const InboxIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.827 2.167h6.347a1.834 1.834 0 011.64 1.017l2.3 4.592a.5.5 0 01.053.224v4a1.833 1.833 0 01-1.833 1.834H2.667A1.833 1.833 0 01.833 12V8a.5.5 0 01.053-.224l2.3-4.592a1.836 1.836 0 011.64-1.017zm0 1a.833.833 0 00-.746.462v.002L2.143 7.5h3.19a.5.5 0 01.417.222L6.934 9.5h2.132l1.185-1.777a.5.5 0 01.416-.223h3.19L11.92 3.63a.834.834 0 00-.747-.463M14.167 8.5h-3.233L9.75 10.278a.5.5 0 01-.416.222H6.667a.5.5 0 01-.416-.222L5.066 8.5H1.834V12a.833.833 0 00.833.834h10.666a.833.833 0 00.834-.834V8.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
