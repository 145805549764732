import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const GridIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M14 9.333H9.333V14H14V9.333zM6.667 9.333H2V14h4.667V9.333zM14 2H9.333v4.667H14V2zM6.667 2H2v4.667h4.667V2z"
        stroke={color || 'currentColor'}
        strokeWidth={1.3333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
