import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const MoreHorizontalIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M3.333 6.833a1.167 1.167 0 100 2.333 1.167 1.167 0 000-2.333zM8 6.833a1.167 1.167 0 100 2.333 1.167 1.167 0 000-2.333zM12.667 6.833a1.167 1.167 0 100 2.333 1.167 1.167 0 000-2.333z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
