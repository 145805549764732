import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const BullHornIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3.016c-.863.957-1.672 1.69-2.586 2.207-.904.512-1.885.798-3.08.909v3.736c1.195.111 2.176.397 3.08.909.914.517 1.723 1.25 2.586 2.207V3.016zm-5.667 7.857c1.039.106 1.852.357 2.589.774.866.49 1.66 1.227 2.602 2.308.498.57 1.476.243 1.476-.548V2.593c0-.792-.978-1.12-1.476-.548-.941 1.08-1.736 1.818-2.602 2.308-.855.483-1.81.744-3.102.814H3a1.5 1.5 0 00-1.5 1.5v2.666a1.5 1.5 0 001.5 1.5h.167v2.834c0 .644.522 1.166 1.166 1.166h1.334c.644 0 1.166-.522 1.166-1.166v-2.794zm-1-1.04H3a.5.5 0 01-.5-.5V6.667a.5.5 0 01.5-.5h2.833v3.666zm0 1v2.834a.167.167 0 01-.166.166H4.333a.167.167 0 01-.166-.166v-2.834h1.666zm8.401-4.237a.5.5 0 01.684-.182 1.833 1.833 0 010 3.172.5.5 0 11-.502-.865.834.834 0 000-1.442.5.5 0 01-.182-.683z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
