import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const MembershipIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.167 1.337C6.167.692 6.689.17 7.333.17h1.334c.644 0 1.166.522 1.166 1.167v.833H14c1.013 0 1.833.82 1.833 1.833v8c0 1.013-.82 1.834-1.833 1.834H2a1.833 1.833 0 01-1.833-1.834v-8C.167 2.991.987 2.17 2 2.17h4.167v-.833zm0 1.833H2a.833.833 0 00-.833.833v8c0 .46.373.834.833.834v-.834a2.5 2.5 0 012.5-2.5h2.667a2.5 2.5 0 012.5 2.5v.834H14c.46 0 .833-.373.833-.834v-8A.833.833 0 0014 3.17H9.833v1H6.167v-1zm2.666 0V1.337a.167.167 0 00-.166-.167H7.333a.167.167 0 00-.166.167V3.17h1.666zm-.166 9.667v-.834a1.5 1.5 0 00-1.5-1.5H4.5a1.5 1.5 0 00-1.5 1.5v.834h5.667zm-2.834-7a1.167 1.167 0 100 2.333 1.167 1.167 0 000-2.333zM3.667 7.003a2.167 2.167 0 114.333 0 2.167 2.167 0 01-4.333 0zm6.166-.666a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5zm0 2a.5.5 0 01.5-.5h3a.5.5 0 110 1h-3a.5.5 0 01-.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
