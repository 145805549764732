import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const InfoIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99988 1.99797C4.68525 1.99797 1.99821 4.68501 1.99821 7.99964C1.99821 11.3143 4.68525 14.0013 7.99988 14.0013C11.3145 14.0013 14.0015 11.3143 14.0015 7.99964C14.0015 4.68501 11.3145 1.99797 7.99988 1.99797ZM0.668213 7.99964C0.668213 3.95047 3.95071 0.667969 7.99988 0.667969C12.049 0.667969 15.3315 3.95047 15.3315 7.99964C15.3315 12.0488 12.049 15.3313 7.99988 15.3313C3.95071 15.3313 0.668213 12.0488 0.668213 7.99964ZM7.33488 5.33297C7.33488 4.9657 7.63261 4.66797 7.99988 4.66797H8.00655C8.37382 4.66797 8.67155 4.9657 8.67155 5.33297C8.67155 5.70024 8.37382 5.99797 8.00655 5.99797H7.99988C7.63261 5.99797 7.33488 5.70024 7.33488 5.33297ZM7.99988 7.33464C8.36715 7.33464 8.66488 7.63237 8.66488 7.99964V10.6663C8.66488 11.0336 8.36715 11.3313 7.99988 11.3313C7.63261 11.3313 7.33488 11.0336 7.33488 10.6663V7.99964C7.33488 7.63237 7.63261 7.33464 7.99988 7.33464Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
