import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const WorkoutIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.333 2.7a1.5 1.5 0 00-1.5 1.5v8a1.5 1.5 0 003 0V9.033h4.334V12.2a1.5 1.5 0 003 0v-8a1.5 1.5 0 10-3 0v3.833H5.833V4.2a1.5 1.5 0 00-1.5-1.5zM3.98 3.846a.5.5 0 01.853.354v8a.5.5 0 01-1 0v-8a.5.5 0 01.147-.354zm7.687-.146a.5.5 0 00-.5.5v8a.5.5 0 001 0v-8a.5.5 0 00-.5-.5z"
        fill={color || 'currentColor'}
      />
      <path
        d="M2 5.033v7H1v-3H0v-1h1v-3h1zM15 5.033v3h1v1h-1v3h-1v-7h1z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
