import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const NeutralEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M8.964 15.75h6.429"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M8.964 10.54a.682.682 0 100-1.365.682.682 0 000 1.364zm6.072 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
    </BaseEmojiIcon>
  )
}
