import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ExclamationTriangleIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.1 1.667a1.833 1.833 0 012.468.647v.003l5.652 9.433a1.833 1.833 0 01-1.568 2.75H2.347A1.834 1.834 0 01.78 11.75l.004-.007 5.647-9.426.429.256-.427-.259c.163-.27.393-.492.668-.647zm.188 1.165l-5.643 9.421a.834.834 0 00.711 1.247h11.288a.833.833 0 00.711-1.247l-5.642-9.42-.001-.001a.833.833 0 00-1.424 0z"
        fill={color || 'currentColor'}
        strokeWidth={1.33333}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5.5a.5.5 0 01.5.5v2.667a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zm-.5 5.833a.5.5 0 01.5-.5h.007a.5.5 0 010 1H8a.5.5 0 01-.5-.5z"
        fill={color || 'currentColor'}
        strokeWidth={1.33333}
      />
    </BaseSvgIcon>
  )
}
