import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LinkIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.633 1.954a3.833 3.833 0 015.42 5.42l-.006.007-2 2a3.834 3.834 0 01-5.78-.415.5.5 0 01.8-.599 2.833 2.833 0 004.273.306l1.996-1.996A2.833 2.833 0 009.33 2.67L8.186 3.808A.5.5 0 117.48 3.1l1.152-1.145zM5.324 5.739a3.833 3.833 0 014.41 1.295.5.5 0 11-.801.6 2.834 2.834 0 00-4.273-.307L2.663 9.324A2.833 2.833 0 006.67 13.33l1.136-1.137a.5.5 0 01.707.707l-1.146 1.147a3.833 3.833 0 01-5.42-5.42l.006-.007 2-2c.389-.389.856-.69 1.371-.881z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
