import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PaperPlaneIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1368 0.862747C15.3965 1.12245 15.3965 1.5435 15.1368 1.8032L7.80344 9.13653C7.54374 9.39623 7.12269 9.39623 6.86299 9.13653C6.60329 8.87683 6.60329 8.45578 6.86299 8.19608L14.1963 0.862747C14.456 0.603048 14.8771 0.603048 15.1368 0.862747Z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1368 0.862747C15.3174 1.04339 15.3786 1.31155 15.2942 1.55267L10.6275 14.886C10.5375 15.1434 10.2994 15.3197 10.027 15.3308C9.75455 15.3419 9.50294 15.1856 9.3922 14.9364L6.82941 9.17013L1.06313 6.60734C0.813972 6.4966 0.657654 6.24499 0.668769 5.97255C0.679884 5.70011 0.856178 5.46207 1.11353 5.37199L14.4469 0.705325C14.688 0.620933 14.9561 0.682107 15.1368 0.862747ZM3.13608 6.07321L7.6033 8.05864C7.75374 8.1255 7.87404 8.2458 7.9409 8.39624L9.92633 12.8635L13.5826 2.41692L3.13608 6.07321Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
