import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ThumbsUpIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.394 13.916c.193.166.44.255.695.25h1.29V7.834h-1.29a1.04 1.04 0 00-1.044.87v4.594c.04.24.163.46.349.62zM5.378 7.44l2.477-5.574a1.5 1.5 0 011.19 1.467V6a.5.5 0 00.5.5h3.779a.833.833 0 01.833.958l-.92 6a.836.836 0 01-.833.709H5.378V7.44zm-2.276 7.727A2.04 2.04 0 011.05 13.4a.502.502 0 01-.004-.067V8.667a.5.5 0 01.004-.067 2.04 2.04 0 012.053-1.767h1.451L7.088 1.13a.5.5 0 01.457-.297 2.5 2.5 0 012.5 2.5V5.5h3.27a1.833 1.833 0 011.831 2.108l-.92 6a1.835 1.835 0 01-1.83 1.559H3.102z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
