import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CheckmarkIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      stroke={color || 'currentColor'}
      strokeWidth={strokeWidth || 2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...rest}>
      {title && <title>{title}</title>}
      <polyline points="20 6 9 17 4 12"></polyline>
    </BaseSvgIcon>
  )
}
