import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CogIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM5.5 8a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98 15.832h.04A1.833 1.833 0 009.78 14v-.053a.6.6 0 01.393-.545l.014-.005.015-.006a.6.6 0 01.66.118l.038.037a1.834 1.834 0 102.593-2.593l-.037-.038a.6.6 0 01-.118-.66v-.002l.002-.003a.6.6 0 01.548-.364H14a1.833 1.833 0 100-3.666h-.053a.6.6 0 01-.544-.393l-.006-.015-.006-.014a.6.6 0 01.118-.66l.038-.038a1.833 1.833 0 10-2.594-2.594l-.038.038a.6.6 0 01-.66.118l-.096-.042h-.04a.6.6 0 01-.286-.508V2a1.833 1.833 0 10-3.666 0v.112a.6.6 0 01-.286.508h-.04l-.096.042a.6.6 0 01-.66-.118l-.038-.038A1.833 1.833 0 102.453 5.1l.038.038a.6.6 0 01.118.66l-.006.014-.006.015a.6.6 0 01-.544.393H2a1.833 1.833 0 100 3.667h.112a.6.6 0 01.549.363v.003l.002.002a.6.6 0 01-.119.66l-.037.038a1.832 1.832 0 00.594 2.992 1.834 1.834 0 002-.399l.037-.037a.6.6 0 01.66-.118l.015.006.014.005a.6.6 0 01.393.545V14a1.833 1.833 0 001.76 1.832zM8 1.167A.833.833 0 007.167 2v.115a1.6 1.6 0 01-.97 1.464l-.094.04h-.061a1.6 1.6 0 01-1.658-.362l-.002-.002-.042-.042a.833.833 0 00-1.18 0 .833.833 0 000 1.18l.042.042.002.002a1.6 1.6 0 01.326 1.75A1.6 1.6 0 012.072 7.22H2a.833.833 0 100 1.667h.115a1.6 1.6 0 011.464.967 1.6 1.6 0 01-.321 1.762l-.002.002-.043.042a.833.833 0 000 1.18.832.832 0 001.18 0l.042-.042.002-.002a1.6 1.6 0 011.75-.327 1.6 1.6 0 011.033 1.46V14a.833.833 0 00.78.832.834.834 0 00.78-.832V13.928a1.6 1.6 0 011.033-1.459 1.6 1.6 0 011.75.327l.002.002.042.042a.832.832 0 001.18 0 .835.835 0 000-1.18l-.043-.042-.001-.002a1.6 1.6 0 01-.322-1.762 1.6 1.6 0 011.464-.967H14a.833.833 0 100-1.667H13.928a1.6 1.6 0 01-1.458-1.033 1.6 1.6 0 01.326-1.75l.002-.002.042-.042a.832.832 0 000-1.18.834.834 0 00-1.18 0l-.042.042-.002.002a1.6 1.6 0 01-1.658.363h-.06l-.095-.04a1.6 1.6 0 01-.97-1.465V2A.833.833 0 008 1.167z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
