import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ThumbsDownIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.818 2.084a1.04 1.04 0 00-.696-.25h-1.289v6.333h1.29a1.04 1.04 0 001.044-.87V2.704a1.04 1.04 0 00-.349-.62zm-2.985 6.477l-2.477 5.573a1.5 1.5 0 01-1.19-1.467V10a.5.5 0 00-.5-.5H2.889a.833.833 0 01-.834-.958l.92-6a.836.836 0 01.834-.708h7.025V8.56zM13.11.834A2.04 2.04 0 0115.162 2.6a.5.5 0 01.005.067v4.667a.5.5 0 01-.005.067 2.04 2.04 0 01-2.053 1.766h-1.45L9.123 14.87a.5.5 0 01-.457.297 2.5 2.5 0 01-2.5-2.5V10.5H2.896a1.833 1.833 0 01-1.83-2.108l.92-6A1.835 1.835 0 013.816.834h9.293z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
