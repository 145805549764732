import React from 'react'

export type IconSizes = 'small' | 'large'
export interface BaseEmojiIconProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode
  className?: string
  viewBox?: string
  size?: IconSizes
  title?: string
}

export const sizesLookup = {
  xsmall: 18,
  small: 24,
  large: 56,
}

export const BaseEmojiIcon = ({
  children,
  className,
  viewBox,
  size = 'small',
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <svg
      className={className}
      width={sizesLookup[size]}
      height={sizesLookup[size]}
      viewBox={viewBox}
      {...rest}>
      {children}
    </svg>
  )
}
