import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const UserIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
        stroke={color || 'currentColor'}
        fill={color || 'currentColor'}></path>
      <circle
        cx="12"
        cy="7"
        r="4"
        stroke={color || 'currentColor'}
        fill={color || 'currentColor'}></circle>
    </BaseSvgIcon>
  )
}
