import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const RefreshSlashIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M9.525 2.719a5.5 5.5 0 00-4.828.882l-.816-.63a6.5 6.5 0 018.707.43l2.245 2.11V2.667a.5.5 0 011 0v4a.5.5 0 01-.5.5h-4a.5.5 0 010-1h2.738l-2.173-2.042-.012-.011a5.5 5.5 0 00-2.361-1.395zM2.564 4.432a6.5 6.5 0 00-.695 1.402.5.5 0 00.942.333 5.5 5.5 0 01.547-1.12l8.671 6.7a5.511 5.511 0 01-2.811 1.616 5.5 5.5 0 01-5.104-1.476l-.012-.011L1.93 9.834h2.738a.5.5 0 000-1h-4a.5.5 0 00-.5.5v4a.5.5 0 101 0V10.49l2.245 2.11a6.5 6.5 0 009.411-.24l1.871 1.446a.5.5 0 00.612-.791l-14-10.819a.5.5 0 10-.612.791l1.87 1.445z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
