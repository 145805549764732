import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

interface StarIconProps extends BaseSvgIconProps {
  gradient?: boolean
}

export const StarIcon = ({
  className,
  color,
  fill,
  size,
  title,
  viewBox = '0 0 18 18',
  gradient = false,
  ...rest
}: StarIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox={viewBox}
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M8 1.333l2.06 4.174 4.607.673-3.334 3.247.787 4.586L8 11.847l-4.12 2.166.787-4.586L1.333 6.18l4.607-.673L8 1.333z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={fill ? fill : gradient ? 'url(#paint0_linear_416_2024)' : 'none'}
      />
      {gradient && (
        <defs>
          <linearGradient
            id="paint0_linear_416_2024"
            x1="6.36429"
            y1="-1"
            x2="0.913689"
            y2="9.20957"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#E56F53" />
            <stop offset="1" stopColor="#85244D" />
          </linearGradient>
        </defs>
      )}
    </BaseSvgIcon>
  )
}
