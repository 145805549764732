import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const GrinEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}

      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M17.336 15.088c-1.789 4.395-8.883 4.395-10.672 0-.104-.255.135-.501.402-.435 3.726.914 6.144.914 9.868 0 .267-.066.506.18.402.435z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M8.964 10.54a.682.682 0 100-1.365.682.682 0 000 1.364zm6.072 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
    </BaseEmojiIcon>
  )
}
