import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArrowDownIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.833a.5.5 0 01.5.5v8.127l3.813-3.814a.5.5 0 11.707.708L8.354 13.02a.5.5 0 01-.708 0L2.98 8.354a.5.5 0 11.707-.708L7.5 11.46V3.333a.5.5 0 01.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
