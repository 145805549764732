import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LevelIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  opacity,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 14 14"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16663 11.9582V2.479C8.16663 2.05416 8.1657 1.77953 8.13849 1.57719C8.11298 1.38743 8.07179 1.32795 8.03848 1.29464C8.00518 1.26134 7.9457 1.22015 7.75594 1.19464C7.5536 1.16743 7.27897 1.1665 6.85413 1.1665C6.42928 1.1665 6.15466 1.16743 5.95231 1.19464C5.76255 1.22015 5.70307 1.26134 5.66977 1.29464C5.63646 1.32795 5.59527 1.38743 5.56976 1.57719C5.54255 1.77953 5.54163 2.05416 5.54163 2.479V11.9582H8.16663Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M4.66663 5.104C4.66663 4.86238 4.47075 4.6665 4.22913 4.6665H2.47913C2.2375 4.6665 2.04163 4.86238 2.04163 5.104V11.9582H4.66663V5.104Z"
        fill={color || 'currentColor'}
        fillOpacity={opacity || '0.4'}
      />
      <path
        d="M11.6666 8.021C11.6666 7.77937 11.4708 7.5835 11.2291 7.5835H9.47913C9.2375 7.5835 9.04163 7.77937 9.04163 8.021V11.9585H11.6666V8.021Z"
        fill={color || 'currentColor'}
        fillOpacity={opacity || '0.4'}
      />
      <path
        d="M1.02087 11.9585C0.779249 11.9585 0.583374 12.1544 0.583374 12.396C0.583374 12.6376 0.779249 12.8335 1.02087 12.8335H12.6875C12.9292 12.8335 13.125 12.6376 13.125 12.396C13.125 12.1544 12.9292 11.9585 12.6875 11.9585H12.5417H11.6667H9.04171H8.16671H5.54171H4.66671H2.04171H1.16671H1.02087Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
