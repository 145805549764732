import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ChatIcon = ({ className, color, size }: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24">
      <path
        d="M20.0182 15.6273C21.5455 14.1 22.5 12.1909 22.5 10.0909C22.5 5.31818 17.8227 1.5 12 1.5C6.17727 1.5 1.5 5.31818 1.5 10.0909C1.5 14.8636 6.17727 18.6818 12 18.6818C13.05 18.6818 14.0045 18.5864 14.9591 18.3L20.5909 20.5909L20.0182 15.6273Z"
        stroke={color}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <defs>
        <linearGradient
          id="paint0_GRADIENT_PRIMARY_DEFAULT"
          x1={-0.6}
          y1={26.1273}
          x2={28.6079}
          y2={20.5248}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#830051" />
          <stop offset={1} stopColor="#FF6D42" />
        </linearGradient>
        <linearGradient
          id="paint0_GRADIENT_PRIMARY_HOVER"
          x1={-0.6}
          y1={26.1273}
          x2={28.6079}
          y2={20.5248}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4F0031" />
          <stop offset={1} stopColor="#CC5735" />
        </linearGradient>
      </defs>
    </BaseSvgIcon>
  )
}
