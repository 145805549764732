import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const HeartIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7 2.499a3.168 3.168 0 00-2.24 5.407L8 13.446l5.54-5.54a3.168 3.168 0 00-4.48-4.48l-.424.425a.9.9 0 01-1.272 0l-.424-.424a3.168 3.168 0 00-2.24-.928zm-2.947.22a4.168 4.168 0 015.894 0L8 3.074l.353-.353a4.167 4.167 0 115.894 5.893l-5.61 5.61a.9.9 0 01-1.273 0l-5.61-5.61a4.168 4.168 0 010-5.893z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
