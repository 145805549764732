import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArrowLeftIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.354 2.98a.5.5 0 010 .707L4.54 7.5h8.127a.5.5 0 110 1H4.54l3.814 3.813a.5.5 0 11-.708.707L2.98 8.354a.5.5 0 010-.708L7.646 2.98a.5.5 0 01.708 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
