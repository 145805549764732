import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ListIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.833 4a.5.5 0 01.5-.5H14a.5.5 0 010 1H5.333a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5H14a.5.5 0 010 1H5.333a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5H14a.5.5 0 010 1H5.333a.5.5 0 01-.5-.5zM1.165 4c0-.461.374-.835.835-.835h.007a.835.835 0 110 1.67H2A.835.835 0 011.165 4zm0 4c0-.461.374-.835.835-.835h.007a.835.835 0 110 1.67H2A.835.835 0 011.165 8zm0 4c0-.461.374-.835.835-.835h.007a.835.835 0 010 1.67H2A.835.835 0 011.165 12z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
