import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const FlameIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.873 1.526c-.384 1.155-.482 2.014-.43 2.681.053.692.27 1.207.545 1.658a8.582 8.582 0 00.525.753c.14.187.287.384.419.585.314.478.576 1.03.576 1.748 0 1.377-.843 2.364-2.021 2.576-.619.111-1.293-.086-1.827-.429-.537-.345-1.013-.889-1.16-1.566-.057-.266-.072-.61-.07-.946.002-.345.022-.718.048-1.057.024-.322.054-.618.078-.842-.825.907-1.384 1.865-1.34 3.305.086 2.811 2.334 5.043 5.07 4.84 2.576-.19 4.5-2.247 4.5-4.825 0-2.11-.976-3.223-2.12-4.337l-.296-.286c-1.02-.98-2.15-2.063-2.497-3.858zm-2.96 4.788zm.188-.16c.003 0 .003 0 0 0zM8.567.468c.142.181.216.412.25.651.233 1.6 1.177 2.512 2.267 3.564l.28.272c1.199 1.167 2.423 2.526 2.423 5.053 0 3.107-2.334 5.594-5.427 5.823-3.372.25-6.042-2.498-6.144-5.807-.06-1.956.814-3.203 1.852-4.277.106-.11.226-.23.345-.326.06-.048.13-.099.205-.14.072-.04.181-.09.315-.1a.608.608 0 01.467.161c.124.116.175.26.199.362.046.197.033.442-.003.701v.004l-.003.018-.009.07a23.363 23.363 0 00-.11 1.108c-.024.327-.043.674-.044.987-.002.322.014.575.048.73.075.348.341.691.722.936.384.246.804.34 1.11.286.67-.12 1.198-.666 1.198-1.592 0-.45-.155-.808-.412-1.2-.114-.173-.24-.342-.381-.53l-.071-.096a9.544 9.544 0 01-.51-.74 4.6 4.6 0 01-.688-2.1c-.068-.874.077-1.928.541-3.26.074-.213.18-.407.32-.559a.905.905 0 01.59-.309.77.77 0 01.67.31z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
