import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const MoonIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.969 1.5C5.404 1.5 2.5 4.43 2.5 8c0 3.6 2.903 6.5 6.469 6.5 1.842 0 3.536-.76 4.745-2.034A5.987 5.987 0 017.094 6.5a5.988 5.988 0 012.623-4.947 6.99 6.99 0 00-.748-.053zM1.5 8C1.5 3.883 4.846.5 8.969.5c.412 0 1.005.067 1.37.133l.01.002c.775.155.917 1.17.241 1.55A4.987 4.987 0 008.094 6.5 4.988 4.988 0 0014 11.415a.81.81 0 01.87.392c.16.28.15.644-.065.93l-.007.01-.007.008A7.523 7.523 0 018.97 15.5C4.847 15.5 1.5 12.15 1.5 8z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
