import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CalendarDayIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.333.167a.5.5 0 01.5.5V1.5h4.334V.667a.5.5 0 111 0V1.5h1.5c1.012 0 1.833.82 1.833 1.833v9.334c0 1.012-.82 1.833-1.833 1.833H3.333A1.833 1.833 0 011.5 12.667V3.333c0-1.012.82-1.833 1.833-1.833h1.5V.667a.5.5 0 01.5-.5zm-.5 2.333h-1.5a.833.833 0 00-.833.833V5.5h11V3.333a.833.833 0 00-.833-.833h-1.5v.833a.5.5 0 01-1 0V2.5H5.833v.833a.5.5 0 11-1 0V2.5zm8.667 4h-11v6.167c0 .46.373.833.833.833h9.334c.46 0 .833-.373.833-.833V6.5z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.167 8.333c0-.644.522-1.166 1.166-1.166h2c.645 0 1.167.522 1.167 1.166v2c0 .644-.522 1.167-1.167 1.167h-2a1.167 1.167 0 01-1.166-1.167v-2zm1.166-.166a.167.167 0 00-.166.166v2c0 .092.074.167.166.167h2a.167.167 0 00.167-.167v-2a.167.167 0 00-.167-.166h-2z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
