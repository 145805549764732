import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CheckboxCheckedIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      size={size}
      fill="none"
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M6 7.333l2 2 6.667-6.666"
        stroke={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 8v4.667A1.334 1.334 0 0112.667 14H3.333A1.334 1.334 0 012 12.667V3.333A1.333 1.333 0 013.333 2h7.334"
        stroke={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
