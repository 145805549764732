import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const UserMessageIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M6.644 1.981a.58.58 0 01.393-.148h7.26a.58.58 0 01.392.148.43.43 0 01.144.315V6.78c0 .11-.046.223-.144.313a.578.578 0 01-.393.147h-1.535l-1.928 1.432V7.24H9.157c-.024.35-.1.685-.218 1h.894v1.426a.5.5 0 00.799.402l2.46-1.828h1.204c.394 0 .781-.142 1.074-.414.291-.27.463-.645.463-1.046V2.296c0-.4-.171-.776-.463-1.047a1.579 1.579 0 00-1.074-.416H7.037c-.396 0-.783.146-1.073.416A1.43 1.43 0 005.5 2.296v1.209a3.553 3.553 0 011 .096V2.296a.43.43 0 01.144-.315z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.667 5.167a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667zM2.833 7A2.833 2.833 0 118.5 7a2.833 2.833 0 01-5.667 0zM1.594 11.717c.494-.557 1.178-.884 1.906-.884h4.333c.728 0 1.412.327 1.906.884.493.556.76 1.298.76 2.06V15a.5.5 0 11-1 0v-1.223c0-.533-.188-1.035-.508-1.396-.318-.36-.737-.548-1.158-.548H3.5c-.421 0-.84.188-1.158.548-.32.36-.509.863-.509 1.396V15a.5.5 0 01-1 0v-1.223c0-.762.268-1.504.76-2.06z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
