import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const DumbbellIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  opacity,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 14 14"
      {...rest}>
      {title && <title>{title}</title>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00171 9.1111L4.8887 11.9981C5.41615 12.5255 5.67987 12.7893 6.00192 12.8269C6.07381 12.8353 6.14644 12.8353 6.21833 12.8269C6.54038 12.7893 6.8041 12.5255 7.33155 11.9981C7.85899 11.4706 8.12271 11.2069 8.16033 10.8849C8.16873 10.813 8.16873 10.7404 8.16033 10.6685C8.12271 10.3464 7.85899 10.0827 7.33155 9.55525L6.68294 8.90664L4.87227 7.09597L4.44455 6.66825C3.9171 6.1408 3.65338 5.87708 3.33133 5.83947C3.25944 5.83107 3.18682 5.83107 3.11493 5.83947C2.79287 5.87708 2.52915 6.14081 2.00171 6.66825C1.47426 7.19569 1.21054 7.45942 1.17292 7.78147C1.16453 7.85336 1.16453 7.92598 1.17292 7.99787C1.21054 8.31993 1.47426 8.58365 2.00171 9.1111ZM7.09609 4.87215L8.90677 6.68282L9.55537 7.33143C10.0828 7.85887 10.3465 8.12259 10.6686 8.16021C10.7405 8.1686 10.8131 8.1686 10.885 8.16021C11.207 8.12259 11.4708 7.85887 11.9982 7.33143C12.5257 6.80398 12.7894 6.54026 12.827 6.21821C12.8354 6.14632 12.8354 6.07369 12.827 6.0018C12.7894 5.67975 12.5257 5.41603 11.9982 4.88858L9.11122 2.00158C8.58377 1.47414 8.32005 1.21042 7.998 1.1728C7.92611 1.1644 7.85348 1.1644 7.78159 1.1728C7.45954 1.21042 7.19582 1.47414 6.66837 2.00158C6.14093 2.52903 5.8772 2.79275 5.83959 3.1148C5.83119 3.18669 5.83119 3.25932 5.83959 3.33121C5.8772 3.65326 6.14093 3.91698 6.66837 4.44443L7.09609 4.87215Z"
        fill={color || 'currentColor'}
      />
      <path
        d="M6.68299 8.90657L8.90681 6.68274L7.09614 4.87207L4.87231 7.09589L6.68299 8.90657Z"
        fill={color || 'currentColor'}
        fillOpacity={opacity || '0.4'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2016 1.14767C10.3725 0.976818 10.6495 0.976818 10.8203 1.14767L12.631 2.95835C12.8019 3.1292 12.8019 3.40621 12.631 3.57706C12.4601 3.74792 12.1831 3.74792 12.0123 3.57706L10.2016 1.76639C10.0308 1.59554 10.0308 1.31853 10.2016 1.14767ZM1.14755 10.2009C1.3184 10.0301 1.59541 10.0301 1.76627 10.2009L3.57694 12.0116C3.7478 12.1825 3.7478 12.4595 3.57694 12.6303C3.40609 12.8012 3.12908 12.8012 2.95822 12.6303L1.14755 10.8197C0.976696 10.6488 0.976696 10.3718 1.14755 10.2009Z"
        fill={color || 'currentColor'}
        fillOpacity={opacity || '0.4'}
      />
    </BaseSvgIcon>
  )
}
