import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const BoltIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.309 1.827c.054-.58.556-.994 1.097-.994h3.602c.737 0 1.228.732 1.062 1.424l-.005.018L9.193 5h2.216c.44 0 .782.267.953.585.17.315.206.739-.022 1.107l-4.39 7.894c-.198.398-.588.58-.968.58-.731 0-1.192-.693-1.066-1.349l.001-.008 1.014-4.476H4.606c-.687 0-1.183-.587-1.096-1.266l.799-6.24zm1.027.036a.087.087 0 00-.031.057l-.001.012-.802 6.262c-.008.06.01.092.023.108.013.015.037.031.081.031h3.577l-1.286 5.678a.159.159 0 00.027.122c.02.027.041.034.058.034a.124.124 0 00.06-.012.022.022 0 00.007-.005.05.05 0 00.008-.012l.006-.014 4.417-7.943.008-.013c.012-.019.022-.057-.006-.109-.03-.053-.063-.06-.073-.06H7.823L9.1 2.012a.176.176 0 00-.03-.135c-.028-.039-.054-.043-.062-.043H5.406a.11.11 0 00-.07.03z"
        fill={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
