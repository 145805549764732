import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const LotusIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.653 2.526a.569.569 0 01.694 0c1.298 1 2.26 2.873 2.808 4.829a7.54 7.54 0 013.766-1.07.57.57 0 01.576.631 7.579 7.579 0 01-7.495 6.737h-.004A7.579 7.579 0 01.503 6.916a.57.57 0 01.576-.632 7.54 7.54 0 013.766 1.07c.548-1.955 1.51-3.829 2.808-4.828zm-.234 10.098a6.583 6.583 0 00-5.846-5.313 6.582 6.582 0 005.846 5.313zm1.162 0a6.582 6.582 0 005.846-5.312 6.587 6.587 0 00-5.846 5.313zm1.702-4.66C9.82 6.133 9 4.467 8 3.541c-1.001.926-1.82 2.592-2.283 4.423A7.597 7.597 0 018 11.06a7.598 7.598 0 012.283-3.095z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
