import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const AnxiousEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M8.794 17.592c-.01.166.126.3.292.3h5.828a.288.288 0 00.292-.3c-.108-1.88-1.25-2.735-3.206-2.735"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M14.914 17.893a.288.288 0 00.292-.3c-.108-1.88-1.25-2.736-3.206-2.736s-3.098.856-3.206 2.735c-.01.166.126.3.292.3h5.828z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M8.964 13.04a.682.682 0 100-1.365.682.682 0 000 1.364zm6.072 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
      <path
        d="M16.113 8.454c1.082 1.16 1.577 1.344 3.32 1.81m-11.19-1.81c-1.082 1.16-1.577 1.344-3.319 1.81"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
    </BaseEmojiIcon>
  )
}
