import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PlusCircleIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.33594C12.3672 7.33594 12.665 7.63367 12.665 8.00094V11.3359H16C16.3672 11.3359 16.665 11.6337 16.665 12.0009C16.665 12.3682 16.3672 12.6659 16 12.6659H12.665V16.0009C12.665 16.3682 12.3672 16.6659 12 16.6659C11.6327 16.6659 11.335 16.3682 11.335 16.0009V12.6659H7.99996C7.63269 12.6659 7.33496 12.3682 7.33496 12.0009C7.33496 11.6337 7.63269 11.3359 7.99996 11.3359H11.335V8.00094C11.335 7.63367 11.6327 7.33594 12 7.33594Z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.66594C6.84438 2.66594 2.66496 6.84536 2.66496 12.0009C2.66496 17.1565 6.84438 21.3359 12 21.3359C17.1555 21.3359 21.335 17.1565 21.335 12.0009C21.335 6.84536 17.1555 2.66594 12 2.66594ZM1.33496 12.0009C1.33496 6.11082 6.10984 1.33594 12 1.33594C17.8901 1.33594 22.665 6.11082 22.665 12.0009C22.665 17.8911 17.8901 22.6659 12 22.6659C6.10984 22.6659 1.33496 17.8911 1.33496 12.0009Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
