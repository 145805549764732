import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

interface BookOpenIconProps extends BaseSvgIconProps {
  weight?: 'thin' | 'regular'
}

const PATHS_BY_WEIGHT = {
  thin: 'M8 3.03c-1.123-.52-2.16-.78-3.23-.817-1.133-.04-2.273.173-3.554.554a1.477 1.477 0 00-1.05 1.42v8.626c0 .672.654 1.112 1.263.937 2.694-.778 4.637-.31 6.317.68a.5.5 0 00.508 0c1.68-.99 3.623-1.458 6.317-.68.609.175 1.262-.265 1.262-.937V4.187c0-.645-.414-1.232-1.048-1.42-1.282-.381-2.422-.593-3.555-.554-1.07.037-2.107.298-3.23.816zm-.5.87c-.988-.446-1.871-.656-2.764-.687-.984-.035-2.008.147-3.235.512a.477.477 0 00-.334.462v8.598c2.584-.743 4.602-.446 6.333.381V3.9zm1 9.266c1.731-.827 3.75-1.124 6.333-.381V4.187c0-.22-.14-.404-.333-.462-1.228-.365-2.252-.547-3.235-.512-.894.03-1.777.241-2.765.687v9.266z',
  regular:
    'M8.00004 2.84789C6.88624 2.34242 5.84856 2.08559 4.77602 2.04824C3.61843 2.00793 2.45886 2.22483 1.1685 2.6085C0.461591 2.81869 0.00170898 3.47202 0.00170898 4.18681V12.8129C0.00170898 13.6013 0.766544 14.1126 1.47463 13.9082C4.12265 13.1438 6.019 13.6035 7.66222 14.5726C7.87066 14.6955 8.12943 14.6955 8.33786 14.5726C9.98108 13.6035 11.8774 13.1438 14.5255 13.9082C15.2335 14.1126 15.9984 13.6013 15.9984 12.8129V4.18681C15.9984 3.47202 15.5385 2.81869 14.8316 2.6085C13.5412 2.22483 12.3817 2.00793 11.2241 2.04824C10.1515 2.08559 9.11385 2.34242 8.00004 2.84789ZM7.33504 4.00751C6.40313 3.5987 5.56958 3.40668 4.72973 3.37743C3.77137 3.34406 2.76575 3.52112 1.54756 3.88334C1.4264 3.91936 1.33171 4.0377 1.33171 4.18681V12.5674C3.7421 11.9191 5.67038 12.1702 7.33504 12.9087V4.00751ZM8.66504 12.9087C10.3297 12.1702 12.258 11.9191 14.6684 12.5674V4.18681C14.6684 4.0377 14.5737 3.91936 14.4525 3.88334C13.2343 3.52112 12.2287 3.34406 11.2704 3.37743C10.4305 3.40668 9.59696 3.5987 8.66504 4.00751V12.9087Z',
}

export const BookOpenIcon = ({
  className,
  color,
  size,
  title,
  weight = 'thin',
  ...rest
}: BookOpenIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={PATHS_BY_WEIGHT[weight]}
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
