import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const HeartFilledIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M4.7 1.164A4.503 4.503 0 001.516 8.85l5.61 5.61a1.235 1.235 0 001.747 0l5.61-5.61a4.503 4.503 0 10-6.367-6.367L8 2.599l-.116-.116A4.502 4.502 0 004.7 1.164z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
