import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export function MinusGradientIcon({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 14 14"
      {...rest}>
      {title && <title>{title}</title>}
      <rect
        x="2"
        y="5.5"
        width="10"
        height="3"
        rx="1.5"
        fill="url(#paint0_linear_16_18617)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_18617"
          x1="8.23282"
          y1="5.5"
          x2="7.69571"
          y2="8.68942"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF4040" />
          <stop offset="1" stopColor="#D80000" />
        </linearGradient>
      </defs>
    </BaseSvgIcon>
  )
}
