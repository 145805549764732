import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CameraIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.584 1.723A.5.5 0 016 1.5h4a.5.5 0 01.416.223L11.601 3.5h2.4a1.833 1.833 0 011.832 1.833v7.334A1.833 1.833 0 0114 14.5H2a1.833 1.833 0 01-1.833-1.833V5.333A1.833 1.833 0 012 3.5h2.4l1.184-1.777zm.684.777L5.083 4.277a.5.5 0 01-.416.223H2a.833.833 0 00-.833.833v7.334A.833.833 0 002 13.5h12a.833.833 0 00.833-.833V5.333A.833.833 0 0014 4.5h-2.667a.5.5 0 01-.416-.223L9.732 2.5H6.268z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.5a2.167 2.167 0 100 4.333A2.167 2.167 0 008 6.5zM4.833 8.667a3.167 3.167 0 116.334 0 3.167 3.167 0 01-6.334 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
