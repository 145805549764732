import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const FilterIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M6.33301 9.33348C7.43758 9.33348 8.33301 10.2289 8.33301 11.3335C8.33301 12.438 7.43758 13.3335 6.33301 13.3335C5.22844 13.3335 4.33301 12.438 4.33301 11.3335C4.33301 10.2289 5.22844 9.33348 6.33301 9.33348Z"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
      />
      <path
        d="M9.66699 2.66649C8.56242 2.66649 7.66699 3.56192 7.66699 4.66649C7.66699 5.77106 8.56242 6.66649 9.66699 6.66649C10.7716 6.66649 11.667 5.77106 11.667 4.66649C11.667 3.56192 10.7716 2.66649 9.66699 2.66649Z"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
      />
      <path
        d="M8.7627 11.3057L14.6667 11.3057"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
        strokeLinecap="round"
      />
      <path
        d="M7.15332 4.63916H1.33317"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
        strokeLinecap="round"
      />
      <path
        d="M1.33301 11.3057L4.03227 11.3057"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
        strokeLinecap="round"
      />
      <path
        d="M14.667 4.63916L12.1277 4.63916"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth || 1.333}
        strokeLinecap="round"
      />
    </BaseSvgIcon>
  )
}
