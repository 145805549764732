import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ListCheckIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16 4a.5.5 0 01.5-.5H14a.5.5 0 110 1H6.66a.5.5 0 01-.5-.5zm0 4a.5.5 0 01.5-.5H14a.5.5 0 110 1H6.66a.5.5 0 01-.5-.5zm-1.327 4a.5.5 0 01.5-.5H14a.5.5 0 110 1H5.333a.5.5 0 01-.5-.5zM1.165 12c0-.461.374-.835.835-.835h.007a.835.835 0 110 1.67H2A.835.835 0 011.165 12zM4.687 2.313a.5.5 0 010 .708L2.624 5.083a.5.5 0 01-.707 0L.98 4.146a.5.5 0 11.707-.708l.584.584L3.98 2.313a.5.5 0 01.707 0zM4.687 6.313a.5.5 0 010 .708L2.624 9.083a.5.5 0 01-.707 0L.98 8.146a.5.5 0 01.707-.708l.584.584L3.98 6.313a.5.5 0 01.707 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
