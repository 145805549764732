import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PlateUtensilsSlashIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M11.889 2.792a6.471 6.471 0 00-3.89-1.291c-1.569 0-3.009.556-4.132 1.483l.815.63A5.476 5.476 0 018 2.5c1.317 0 2.525.463 3.472 1.234.1-.33.237-.645.417-.943zM7.265 5.61l-.907-.7a3.5 3.5 0 015.048 3.902l-.908-.703.002-.108a2.5 2.5 0 00-3.235-2.39z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.943 11.715A5.486 5.486 0 008 13.501a5.485 5.485 0 004.031-1.759l-1.593-1.23a3.5 3.5 0 01-5.484-4.237l-.32-.25a1.5 1.5 0 01-1.385.923H3v7.385a.5.5 0 11-1 0V6.948h-.249A1.5 1.5 0 01.256 5.326L.46 2.801.444 2.79a.5.5 0 11.612-.791l13.777 10.646V9.613h-1.866a.8.8 0 01-.8-.8V5.666c0-1.038.166-1.852.607-2.508.44-.656 1.108-1.085 1.955-1.451a.792.792 0 011.104.73v11.23a.5.5 0 01-.857.35.464.464 0 01-.032-.024l-2.119-1.637A6.484 6.484 0 018 14.501a6.473 6.473 0 01-4.057-1.421v-1.365zM8 10.501a2.5 2.5 0 01-2.242-3.607l3.878 2.997a2.49 2.49 0 01-1.636.61zm6.833-7.743c-.601.292-.982.59-1.229.957-.284.423-.437 1.008-.437 1.951v2.947h1.666V2.758zm-13.428.773l-.152 1.876a.5.5 0 00.498.54H2V3.992l-.595-.46zM3 4.764v1.184h.249a.5.5 0 00.498-.54l-.005-.071L3 4.764z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
