import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export function CheckmarkGradientIcon({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 14 14"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M2.625 7.7L3.63312 8.8291C4.32922 9.60872 4.67726 9.99853 5.125 9.99853C5.57274 9.99853 5.92078 9.60872 6.61688 8.8291L11.375 3.5"
        stroke="url(#paint0_linear_16_18615)"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_18615"
          x1="8.07872"
          y1="3.5"
          x2="3.82098"
          y2="9.10247"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5BD1AE" />
          <stop offset="1" stopColor="#00AA44" />
        </linearGradient>
      </defs>
    </BaseSvgIcon>
  )
}
