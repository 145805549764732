import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const StopWatchIcon = ({
  className,
  color,
  size,
  title,
  strokeWidth,
  opacity,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 14 15"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39587 2.104C5.39587 1.86238 5.59175 1.6665 5.83337 1.6665H8.16671C8.40833 1.6665 8.60421 1.86238 8.60421 2.104C8.60421 2.34563 8.40833 2.5415 8.16671 2.5415H5.83337C5.59175 2.5415 5.39587 2.34563 5.39587 2.104Z"
        fill={color || 'currentColor'}
      />
      <g opacity={opacity || '0.4'}>
        <path
          d="M7 13.9165C9.8995 13.9165 12.25 11.566 12.25 8.6665C12.25 5.76701 9.8995 3.4165 7 3.4165C4.1005 3.4165 1.75 5.76701 1.75 8.6665C1.75 11.566 4.1005 13.9165 7 13.9165Z"
          fill={color || 'currentColor'}
        />
      </g>
      <path
        d="M7.59852 7.0772C7.46012 6.98001 7.30337 6.87948 7.14109 6.78079L7.14109 6.78079C6.51552 6.40038 6.20273 6.21017 5.9222 6.42076C5.64167 6.63135 5.61617 7.0722 5.56518 7.95391C5.55076 8.20325 5.54163 8.4477 5.54163 8.66683C5.54163 8.88596 5.55076 9.1304 5.56518 9.37975C5.61617 10.2615 5.64167 10.7023 5.9222 10.9129C6.20273 11.1235 6.51552 10.9333 7.14109 10.5529C7.30337 10.4542 7.46012 10.3536 7.59852 10.2565C7.75629 10.1457 7.92797 10.0127 8.10107 9.87153C8.72811 9.36002 9.04163 9.10426 9.04163 8.66683C9.04163 8.2294 8.72811 7.97364 8.10107 7.46213C7.92797 7.32093 7.75629 7.18798 7.59852 7.0772Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
