import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ReplaceIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.98.313a.5.5 0 01.707 0l2.667 2.667a.5.5 0 010 .707l-2.667 2.667a.5.5 0 01-.707-.708l1.813-1.813H4.667A2.167 2.167 0 002.5 6v1.333a.5.5 0 01-1 0V6a3.167 3.167 0 013.167-3.167h8.126L10.98 1.02a.5.5 0 010-.707zM14 8.167a.5.5 0 01.5.5V10a3.166 3.166 0 01-3.167 3.167H3.207L5.02 14.98a.5.5 0 11-.707.707L1.646 13.02a.5.5 0 010-.707l2.667-2.667a.5.5 0 11.707.707l-1.813 1.814h8.126A2.166 2.166 0 0013.5 10V8.667a.5.5 0 01.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
