import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArrowUpFromBracketIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 448 512"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M384 352v64c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32v-64c0-17.67-14.33-32-32-32s-32 14.33-32 32v64c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96v-64c0-17.67-14.33-32-32-32S384 334.3 384 352zM201.4 9.375l-128 128c-12.51 12.51-12.49 32.76 0 45.25c12.5 12.5 32.75 12.5 45.25 0L192 109.3V320c0 17.69 14.31 32 32 32s32-14.31 32-32V109.3l73.38 73.38c12.5 12.5 32.75 12.5 45.25 0s12.5-32.75 0-45.25l-128-128C234.1-3.125 213.9-3.125 201.4 9.375z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
