import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const BeamingEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M8.29 14.716a.665.665 0 10-.973.907l.973-.907zm8.393.907a.665.665 0 10-.973-.907l.973.907zm-9.366 0c1.355 1.452 2.996 2.22 4.683 2.22 1.687 0 3.328-.768 4.683-2.22l-.973-.907c-1.145 1.228-2.45 1.798-3.71 1.798-1.26 0-2.565-.57-3.71-1.798l-.973.907zM6.36 10.389a.665.665 0 101.28.365l-1.28-.365zm3.75.365a.665.665 0 001.28-.365l-1.28.365zm2.5-.365a.665.665 0 101.28.365l-1.28-.365zm3.75.365a.665.665 0 101.28-.365l-1.28.365zm-8.72 0c.312-1.094.889-1.393 1.235-1.393.346 0 .923.3 1.236 1.393l1.278-.365c-.401-1.406-1.342-2.358-2.514-2.358-1.172 0-2.113.952-2.514 2.358l1.278.365zm6.25 0c.312-1.094.889-1.393 1.235-1.393.346 0 .923.3 1.236 1.393l1.278-.365c-.401-1.406-1.342-2.358-2.514-2.358-1.172 0-2.113.952-2.514 2.358l1.278.365z"
        fill={color || 'currentColor'}
      />
    </BaseEmojiIcon>
  )
}
