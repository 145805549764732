import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const HappyEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M7.317 15.623a.665.665 0 11.973-.907l-.973.907zm8.393-.907a.665.665 0 11.973.907l-.973-.907zm-7.42 0c1.146 1.228 2.45 1.798 3.71 1.798 1.26 0 2.565-.57 3.71-1.798l.973.907c-1.355 1.452-2.996 2.22-4.683 2.22-1.687 0-3.328-.768-4.683-2.22l.973-.907z"
        fill={color || 'currentColor'}
      />
      <path
        d="M8.964 10.54a.682.682 0 100-1.365.682.682 0 000 1.364zm6.072 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
    </BaseEmojiIcon>
  )
}
