import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CheckmarkSquareIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 2.5a.833.833 0 00-.833.833v9.334a.833.833 0 00.833.833h9.334a.833.833 0 00.833-.833V8a.5.5 0 011 0v4.667a1.833 1.833 0 01-1.833 1.833H3.333A1.833 1.833 0 011.5 12.667V3.333A1.833 1.833 0 013.333 1.5h7.334a.5.5 0 010 1H3.333zm11.687-.187a.5.5 0 010 .707L8.354 9.687a.5.5 0 01-.708 0l-2-2a.5.5 0 11.708-.707L8 8.626l6.313-6.313a.5.5 0 01.707 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
