import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ChevronUpIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.646 10.354a.5.5 0 010-.708l4-4a.5.5 0 01.708 0l4 4a.5.5 0 01-.708.708L8 6.707l-3.646 3.647a.5.5 0 01-.708 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
