import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const SoftArrowRightIcon = SoftArrowIcon

export function SoftArrowDownIcon(props) {
  return <SoftArrowIcon {...props} style={{ transform: 'rotate(90deg)' }} />
}

export function SoftArrowIcon({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 6 10"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M1.5 2L4.5 5L1.5 8"
        stroke={color || 'currentColor'}
        strokeWidth="2.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
