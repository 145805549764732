import React from 'react'
import { BaseSvgIconProps } from './base-svg-icon'

export const TriangleDownIcon = ({
  className,
  color,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <svg
      className={className}
      width={23}
      height={8}
      viewBox="0 0 23 8"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M23 0H0l10.358 7.205a2 2 0 002.284 0L23 0z"
        fill={color || 'currentColor'}
      />
    </svg>
  )
}
