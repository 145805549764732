import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PhoneIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.693 2.025c-.11.146-.193.372-.193.642v10.667c0 .27.083.496.193.643.11.146.224.19.307.19h6c.084 0 .197-.044.307-.19.11-.147.193-.373.193-.643V2.667c0-.27-.083-.496-.193-.642-.11-.147-.223-.191-.307-.191H5c-.083 0-.197.044-.307.19zm-.8-.6C4.145 1.089 4.53.834 5 .834h6c.469 0 .855.255 1.107.59.252.336.393.777.393 1.243v10.667c0 .466-.141.907-.393 1.243-.252.336-.638.59-1.107.59H5c-.469 0-.855-.254-1.107-.59a2.078 2.078 0 01-.393-1.243V2.667c0-.466.141-.907.393-1.242z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.447a1 1 0 011-1h.007a1 1 0 110 2H8a1 1 0 01-1-1zM12 10.727H4v-1h8v1z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
