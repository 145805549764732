import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const VideoIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3.833a.833.833 0 00-.833.834v6.666c0 .46.373.834.833.834h7.333c.46 0 .834-.373.834-.834V4.667a.833.833 0 00-.834-.834H2zm9.167 3.195V4.667c0-1.013-.821-1.834-1.834-1.834H2c-1.013 0-1.833.821-1.833 1.834v6.666c0 1.013.82 1.834 1.833 1.834h7.333c1.013 0 1.834-.821 1.834-1.834V8.972l3.876 2.768a.5.5 0 00.79-.407V4.667a.5.5 0 00-.79-.407l-3.876 2.768zm.36.972l3.306 2.362V5.638L11.527 8z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
