import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const QrCodeIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M14.53 14.25a.28.28 0 00-.28.28v.773c0 .155.126.28.28.28h.773a.28.28 0 00.28-.28v-.772a.28.28 0 00-.28-.281h-.772zM12.114 14.25a.28.28 0 00-.28.28v.773c0 .155.125.28.28.28h.772a.28.28 0 00.28-.28v-.772a.28.28 0 00-.28-.281h-.772zM3.24 11.71a.28.28 0 00-.28.28v.773c0 .155.126.28.28.28h.773a.28.28 0 00.28-.28v-.772a.28.28 0 00-.28-.281H3.24zM11.99 2.96a.28.28 0 00-.28.282v.772c0 .155.126.28.28.28h.773a.28.28 0 00.28-.28v-.772a.28.28 0 00-.28-.281h-.772zM3.24 2.96a.28.28 0 00-.28.282v.772c0 .155.126.28.28.28h.773a.28.28 0 00.28-.28v-.772a.28.28 0 00-.28-.281H3.24z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 10.25a1 1 0 011-1h1.275a1 1 0 011 1v1.625H14.5V9.75a.5.5 0 011 0v2.125a1 1 0 01-1 1h-1.975a1 1 0 01-1-1V10.25H10.25V15a.5.5 0 01-1 0v-4.75zM.5 2A1.5 1.5 0 012 .5h3.25A1.5 1.5 0 016.75 2v3.25a1.5 1.5 0 01-1.5 1.5H2a1.5 1.5 0 01-1.5-1.5V2zM2 1.5a.5.5 0 00-.5.5v3.25a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5H2zm7.25.5a1.5 1.5 0 011.5-1.5H14A1.5 1.5 0 0115.5 2v3.25a1.5 1.5 0 01-1.5 1.5h-3.25a1.5 1.5 0 01-1.5-1.5V2zm1.5-.5a.5.5 0 00-.5.5v3.25a.5.5 0 00.5.5H14a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5h-3.25zM.5 10.75A1.5 1.5 0 012 9.25h3.25a1.5 1.5 0 011.5 1.5V14a1.5 1.5 0 01-1.5 1.5H2A1.5 1.5 0 01.5 14v-3.25zm1.5-.5a.5.5 0 00-.5.5V14a.5.5 0 00.5.5h3.25a.5.5 0 00.5-.5v-3.25a.5.5 0 00-.5-.5H2z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
