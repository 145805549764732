import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const CheckmarkCircleIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.51 2.363A6.167 6.167 0 1014.167 8v-.613a.5.5 0 111 0V8a7.167 7.167 0 11-4.25-6.55.5.5 0 11-.407.913zm4.51-.05a.5.5 0 010 .707L8.354 9.693a.5.5 0 01-.708 0l-2-2a.5.5 0 11.708-.707L8 8.633l6.313-6.32a.5.5 0 01.707 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
