import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const DownloadIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5a.5.5 0 01.5.5v6.793l2.48-2.48a.5.5 0 11.707.707l-3.333 3.334a.5.5 0 01-.708 0L4.313 7.02a.5.5 0 01.707-.707l2.48 2.48V2a.5.5 0 01.5-.5zm-6 8a.5.5 0 01.5.5v2.667a.833.833 0 00.833.833h9.334a.833.833 0 00.833-.833V10a.5.5 0 011 0v2.667a1.833 1.833 0 01-1.833 1.833H3.333A1.833 1.833 0 011.5 12.667V10a.5.5 0 01.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
