import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArchiveIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.167 8a.5.5 0 01.5-.5h2.666a.5.5 0 110 1H6.667a.5.5 0 01-.5-.5z"
        fill={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.167 2a.5.5 0 01.5-.5h14.666a.5.5 0 01.5.5v3.333a.5.5 0 01-.5.5H14.5V14a.5.5 0 01-.5.5H2a.5.5 0 01-.5-.5V5.833H.667a.5.5 0 01-.5-.5V2zM2.5 5.833V13.5h11V5.833h-11zm-1.333-1V2.5h13.666v2.333H1.167z"
        fill={color || 'currentColor'}
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
