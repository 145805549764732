import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const WorkoutSlashIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.306 2.196a.5.5 0 10-.612.791l2.14 1.653v7.56a1.5 1.5 0 003 0V9.031h2.683l1.65 1.275V12.2a1.5 1.5 0 002.949.387l1.578 1.22a.5.5 0 10.612-.791l-14-10.819zm10.86 9.657l-1-.773v1.12a.5.5 0 001 0v-.347zm-4.943-3.82l-1.39-1.074v1.073h1.39zm-2.39-1.847l-1-.773V12.2a.5.5 0 001 0V6.186z"
        fill={color || 'currentColor'}
      />
      <path
        d="M11.167 8.386l-1-.772V4.199a1.5 1.5 0 013 0v5.733l-1-.773V4.2a.5.5 0 00-1 0v4.187zM14 10.576l1 .773V9.032h1v-1h-1v-3h-1v5.544zM3.894 2.765l1.94 1.5v-.066a1.5 1.5 0 00-1.94-1.434zM2 12.033v-7H1v3H0v1h1v3h1z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
