import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const BeamingSweatEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M14.557 2.332a10 10 0 107.338 8.221"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M8.08 14.874a.665.665 0 10-.972.908l.972-.908zm8.812.908a.665.665 0 10-.972-.908l.972.908zm-9.784 0c1.416 1.518 3.131 2.32 4.892 2.32 1.76 0 3.476-.802 4.892-2.32l-.972-.908c-1.208 1.295-2.587 1.899-3.92 1.899-1.333 0-2.712-.604-3.92-1.899l-.972.908zM6.11 10.317a.665.665 0 101.28.366l-1.28-.366zm3.938.366a.665.665 0 001.279-.366l-1.279.366zm2.625-.366a.665.665 0 101.279.366l-1.279-.366zm3.938.366a.665.665 0 001.278-.366l-1.278.366zm-9.222 0c.33-1.157.946-1.487 1.33-1.487.384 0 .999.33 1.33 1.487l1.278-.366c-.42-1.468-1.399-2.45-2.608-2.45-1.21 0-2.189.982-2.608 2.45l1.278.366zm6.563 0c.33-1.157.945-1.487 1.33-1.487.383 0 .998.33 1.329 1.487l1.278-.366c-.42-1.468-1.398-2.45-2.608-2.45-1.21 0-2.188.982-2.608 2.45l1.279.366zM19.304 1.704l-.508-.429.508.429zm-.508-.429c-.26.308-.812.984-1.304 1.724-.245.37-.484.769-.664 1.155-.173.37-.325.8-.325 1.208h1.33c0-.112.051-.327.2-.646.142-.304.342-.641.567-.981.45-.679.966-1.31 1.213-1.603l-1.017-.857zm-2.293 4.087c0 .916.37 1.646.95 2.136.565.478 1.295.7 1.999.7.704 0 1.434-.222 2-.7.58-.49.95-1.22.95-2.136h-1.33c0 .531-.203.887-.479 1.12-.29.246-.703.386-1.14.386-.44 0-.851-.14-1.142-.386-.277-.233-.478-.59-.478-1.12h-1.33zm5.898 0c0-.409-.152-.837-.325-1.208A9.128 9.128 0 0021.412 3a20.033 20.033 0 00-1.304-1.724l-1.017.857c.246.292.762.924 1.213 1.603.225.34.425.678.567.981.149.32.2.534.2.646h1.33zm-2.588-3.23a.473.473 0 01-.722 0l1.017-.857a.857.857 0 00-1.312 0l1.017.857z"
        fill={color || 'currentColor'}
      />
    </BaseEmojiIcon>
  )
}
