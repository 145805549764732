import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const RecordDotIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 12 12"
      {...rest}>
      {title && <title>{title}</title>}
      <circle cx="6" cy="6" r="6" fill={color || 'currentColor'} />
    </BaseSvgIcon>
  )
}
