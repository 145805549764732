import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const UsersIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M4.891 7.88c-.25.222-.476.472-.673.743a1.5 1.5 0 00-2.384 1.212v2a.5.5 0 11-1 0v-2A2.5 2.5 0 014.89 7.879zM13.782 8.623a4.706 4.706 0 00-.673-.744 2.5 2.5 0 014.058 1.955v2a.5.5 0 11-1 0v-2a1.5 1.5 0 00-2.385-1.211z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.071 8.5c-1.614 0-2.904 1.28-2.904 2.834v3.333a.5.5 0 11-1 0v-3.333c0-2.127 1.758-3.833 3.904-3.833H9.93c2.146 0 3.904 1.706 3.904 3.833h-1c0-1.555-1.29-2.833-2.904-2.833H8.07zm4.762 2.834v3.333a.5.5 0 101 0v-3.333h-1zM14.667 3.5a.933.933 0 100 1.867.933.933 0 000-1.866zm-1.934.934a1.933 1.933 0 113.867 0 1.933 1.933 0 01-3.867 0zM9 1.834a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667zM6.167 3.667a2.833 2.833 0 115.666 0 2.833 2.833 0 01-5.666 0zM3.333 3.5a.933.933 0 100 1.867.933.933 0 000-1.866zM1.4 4.435a1.933 1.933 0 113.867 0 1.933 1.933 0 01-3.867 0z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
