import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const HelpCircleIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.833a6.167 6.167 0 100 12.333A6.167 6.167 0 008 1.833zM.833 8a7.167 7.167 0 1114.334 0A7.167 7.167 0 01.833 8z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3 4.863a1.54 1.54 0 00-1.84 1.51.5.5 0 01-1 0 2.54 2.54 0 113.04 2.49v.87a.5.5 0 11-1 0v-1.32a.5.5 0 01.5-.5 1.54 1.54 0 00.3-3.05zM7.5 12a.5.5 0 01.5-.5h.007a.5.5 0 110 1H8a.5.5 0 01-.5-.5z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
