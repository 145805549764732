import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PageTextIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11552 2.11552C4.61531 1.61574 5.29316 1.33496 5.99996 1.33496H14C14.1763 1.33496 14.3455 1.40502 14.4702 1.52973L20.4702 7.52974C20.5949 7.65445 20.665 7.82359 20.665 7.99996V20C20.665 20.7068 20.3842 21.3846 19.8844 21.8844C19.3846 22.3842 18.7068 22.665 18 22.665H5.99996C5.29316 22.665 4.61531 22.3842 4.11552 21.8844C3.61574 21.3846 3.33496 20.7068 3.33496 20V3.99996C3.33496 3.29316 3.61574 2.61531 4.11552 2.11552ZM5.99996 2.66496C5.6459 2.66496 5.30633 2.80561 5.05597 3.05597C4.80561 3.30633 4.66496 3.6459 4.66496 3.99996V20C4.66496 20.354 4.80561 20.6936 5.05597 20.9439C5.30633 21.1943 5.6459 21.335 5.99996 21.335H18C18.354 21.335 18.6936 21.1943 18.9439 20.9439C19.1943 20.6936 19.335 20.354 19.335 20V8.27541L13.7245 2.66496H5.99996Z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 1.33496C14.3672 1.33496 14.665 1.63269 14.665 1.99996V7.33496H20C20.3672 7.33496 20.665 7.63269 20.665 7.99996C20.665 8.36723 20.3672 8.66496 20 8.66496H14C13.6327 8.66496 13.335 8.36723 13.335 7.99996V1.99996C13.335 1.63269 13.6327 1.33496 14 1.33496ZM7.33496 8.99996C7.33496 8.63269 7.63269 8.33496 7.99996 8.33496H9.99996C10.3672 8.33496 10.665 8.63269 10.665 8.99996C10.665 9.36723 10.3672 9.66496 9.99996 9.66496H7.99996C7.63269 9.66496 7.33496 9.36723 7.33496 8.99996ZM7.33496 13C7.33496 12.6327 7.63269 12.335 7.99996 12.335H16C16.3672 12.335 16.665 12.6327 16.665 13C16.665 13.3672 16.3672 13.665 16 13.665H7.99996C7.63269 13.665 7.33496 13.3672 7.33496 13ZM7.33496 17C7.33496 16.6327 7.63269 16.335 7.99996 16.335H16C16.3672 16.335 16.665 16.6327 16.665 17C16.665 17.3672 16.3672 17.665 16 17.665H7.99996C7.63269 17.665 7.33496 17.3672 7.33496 17Z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
