import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const HappySweatEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M14.557 2.332a10 10 0 107.338 8.221"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
        strokeLinecap="round"
      />
      <path
        d="M7.317 15.623a.665.665 0 11.973-.907l-.973.907zm8.393-.907a.665.665 0 11.973.907l-.973-.907zm-7.42 0c1.146 1.228 2.45 1.798 3.71 1.798 1.26 0 2.565-.57 3.71-1.798l.973.907c-1.355 1.452-2.996 2.22-4.683 2.22-1.687 0-3.328-.768-4.683-2.22l.973-.907z"
        fill={color || 'currentColor'}
      />
      <path
        d="M8.964 10.54a.682.682 0 100-1.365.682.682 0 000 1.364zm6.072 0a.682.682 0 100-1.365.682.682 0 000 1.364z"
        fill={color || 'currentColor'}
        stroke={color || 'currentColor'}
        strokeWidth={0.65}
      />
      <path
        d="M18.95 2.202l.508.428-.508-.428zm.295 0l-.51.428.51-.428zm-.804-.428c-.251.298-.777.942-1.243 1.646a8.66 8.66 0 00-.631 1.1c-.164.352-.31.764-.31 1.158h1.33c0-.1.046-.297.186-.598.132-.285.32-.604.534-.925.425-.642.913-1.242 1.151-1.525l-1.017-.856zm-2.184 3.904c0 .882.355 1.586.915 2.059.546.46 1.248.674 1.925.674s1.38-.213 1.925-.674c.56-.473.916-1.177.916-2.059h-1.33c0 .497-.189.827-.444 1.043-.27.229-.656.36-1.067.36-.41 0-.796-.132-1.066-.36-.256-.217-.444-.547-.444-1.043h-1.33zm5.68 0c0-.394-.146-.806-.31-1.158a8.673 8.673 0 00-.63-1.1 19.051 19.051 0 00-1.244-1.646l-1.017.856c.238.283.726.883 1.152 1.525.213.321.4.64.534.925.14.301.186.499.186.598h1.33zM19.459 2.63a.473.473 0 01-.722 0l1.017-.856a.857.857 0 00-1.312 0l1.017.856z"
        fill={color || 'currentColor'}
      />
    </BaseEmojiIcon>
  )
}
