import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const GrinHeartsEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M17.336 15.088c-1.789 4.395-8.883 4.395-10.672 0-.104-.255.135-.501.402-.435 3.726.914 6.144.914 9.868 0 .267-.066.506.18.402.435zM6.918 10.878l2.714.815a.143.143 0 00.178-.096l.814-2.714a1.31 1.31 0 10-2.509-.753l-.061.205a.143.143 0 01-.178.096l-.205-.062a1.31 1.31 0 10-.753 2.51zM17.082 10.878l-2.714.815a.143.143 0 01-.178-.096l-.815-2.714a1.31 1.31 0 112.51-.753l.061.205a.143.143 0 00.178.096l.205-.062a1.31 1.31 0 01.753 2.51z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
    </BaseEmojiIcon>
  )
}
