import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ArrowUpIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.646 2.98a.5.5 0 01.708 0l4.666 4.666a.5.5 0 01-.707.708L8.5 4.54v8.127a.5.5 0 01-1 0V4.54L3.687 8.354a.5.5 0 11-.707-.708L7.646 2.98z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
