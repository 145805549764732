import React from 'react'
import { BaseEmojiIcon, BaseEmojiIconProps } from './base-emoji-icon'

export const BeamingGrinEmojiIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseEmojiIconProps) => {
  return (
    <BaseEmojiIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 24 24"
      {...rest}>
      {title && <title>{title}</title>}
      <circle
        cx={12}
        cy={12}
        r={10}
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M17.336 15.088c-1.789 4.395-8.883 4.395-10.672 0-.104-.255.135-.5.402-.435 3.726.914 6.144.914 9.868 0 .267-.066.506.18.402.435z"
        stroke={color || 'currentColor'}
        strokeWidth={1.33}
      />
      <path
        d="M6.36 10.389a.665.665 0 001.28.365l-1.28-.365zm3.75.365a.665.665 0 001.28-.365l-1.28.365zm2.5-.365a.665.665 0 001.28.365l-1.28-.365zm3.75.365a.665.665 0 001.28-.365l-1.28.365zm-8.72 0c.312-1.094.889-1.393 1.235-1.393.346 0 .923.3 1.236 1.393l1.278-.365c-.401-1.406-1.342-2.358-2.514-2.358-1.172 0-2.113.952-2.514 2.358l1.278.365zm6.25 0c.312-1.094.889-1.393 1.235-1.393.346 0 .923.3 1.236 1.393l1.278-.365c-.401-1.406-1.342-2.358-2.514-2.358-1.172 0-2.113.952-2.514 2.358l1.278.365z"
        fill={color || 'currentColor'}
      />
    </BaseEmojiIcon>
  )
}
