import React from 'react'
import { BaseSvgIconProps } from './base-svg-icon'

export const UploadIcon = ({
  className,
  color,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <svg
      className={className}
      width={16}
      height={20}
      viewBox="0 0 16 20"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        d="M1.33337 10.0001V16.6667C1.33337 17.1088 1.50897 17.5327 1.82153 17.8453C2.13409 18.1578 2.55801 18.3334 3.00004 18.3334H13C13.4421 18.3334 13.866 18.1578 14.1786 17.8453C14.4911 17.5327 14.6667 17.1088 14.6667 16.6667V10.0001M11.3334 5.00008L8.00004 1.66675M8.00004 1.66675L4.66671 5.00008M8.00004 1.66675V12.5001"
        stroke={color || 'currentColor'}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  )
}
export default UploadIcon
