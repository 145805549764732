import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const PageUserIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.704 1.37c.344-.344.81-.537 1.296-.537h5.333a.5.5 0 01.354.146l4 4a.5.5 0 01.146.354v8A1.833 1.833 0 0112 15.166H4a1.833 1.833 0 01-1.833-1.833V2.666c0-.486.193-.952.537-1.296zM4 1.833a.833.833 0 00-.833.833v10.667a.833.833 0 00.833.833h8a.833.833 0 00.833-.833V5.54L9.126 1.833H4z"
        fill={color || 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.333.833a.5.5 0 01.5.5v3.5h3.5a.5.5 0 110 1h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5zM8 7.833A.833.833 0 108 9.5a.833.833 0 000-1.667zm-1.833.833a1.833 1.833 0 113.666 0 1.833 1.833 0 01-3.666 0zm1.19 3.5c-1.03 0-1.857.826-1.857 1.834v.666a.5.5 0 11-1 0V14c0-1.57 1.284-2.834 2.857-2.834h1.286c1.573 0 2.857 1.264 2.857 2.834v.666a.5.5 0 11-1 0V14a1.845 1.845 0 00-1.857-1.834H7.357z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
