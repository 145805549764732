import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const ChartUpIcon = ({
  size = 'medium',
  color,
  strokeWidth = 2,
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      size={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 4V18C2 19.1046 2.89543 20 4 20H22M20 8L13.3957 14L9.91979 10.8421L7 13.4947M20 8H15.8289M20 8V11.7895"
        stroke={color || 'currentColor'}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </BaseSvgIcon>
  )
}
