import React from 'react'
import { BaseSvgIcon, BaseSvgIconProps } from './base-svg-icon'

export const RefreshIcon = ({
  className,
  color,
  size,
  title,
  ...rest
}: BaseSvgIconProps) => {
  return (
    <BaseSvgIcon
      className={className}
      fill="none"
      size={size}
      viewBox="0 0 16 16"
      {...rest}>
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.525 2.719A5.5 5.5 0 002.81 6.167a.5.5 0 11-.942-.333A6.5 6.5 0 0112.589 3.4l2.244 2.11V2.667a.5.5 0 011 0v4a.5.5 0 01-.5.5h-4a.5.5 0 010-1h2.738l-2.173-2.042-.012-.011a5.5 5.5 0 00-2.361-1.395zM.167 9.334a.5.5 0 01.5-.5h4a.5.5 0 010 1H1.929l2.173 2.042.012.01a5.5 5.5 0 009.075-2.052.5.5 0 01.942.333A6.499 6.499 0 013.412 12.6l-2.245-2.11v2.844a.5.5 0 11-1 0v-4z"
        fill={color || 'currentColor'}
      />
    </BaseSvgIcon>
  )
}
